import {
  add, compose, reduce, map, prop, values, unless,
} from 'ramda';
import { isArray, } from '@ezugi/utils';
import { BET_MATCHES, } from '../../../../components/BetGrid/constants';

export const getTotalBet = compose(
  reduce(add, 0),
  map(prop('value')),
  unless(isArray, values)
);

export const getCoveredNumbers = (bets, seed = []) => {
  const coveredNumbers = Object.keys(bets).reduce((acc, x) => {
    x < 200 ? acc.push(+x) : acc.push(...BET_MATCHES[`index-${x}`].filter((i) => i < 200));
    return acc;
  }, seed);

  // unique keys:
  return [ ...new Set(coveredNumbers), ];
};
