import {
  compose, values, filter, prop, reduce,
} from 'ramda';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';

import { getTotalBet, } from './core';
import {
  currentBetsSelector,
  betsHistorySelector,
  userSelector,
  tableIdSelector,
  roundIdSelector,
  gameIdSelector,
  gameSelector,
} from '../../../selectors';
import betActions from '../../../actions/bets';

const { notificationActions, socketActions, } = bootstrapActions;
const keepValidBets = filter(prop('valid'));

const buildParams = (bets, state) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).serverName || gameIdSelector(state),
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    BetsList: bets,
  };
};

export const createBetRequestPayload = (state) => {
  const currentBetsList = compose(
    values,
    currentBetsSelector
  )(state);
  const betHistory = betsHistorySelector(state);
  const actions = [];

  if (betHistory.length <= 1) return actions;

  if (currentBetsList.length) {
    const validBetsList = keepValidBets(currentBetsList);

    if (validBetsList.length) {
      const betsToSend = reduce(
        (acc, { index, value, }) => [
          ...acc,
          {
            index: +index,
            betValue: value,
            group: '1',
            betType: 'bet',
          },
        ],
        [],
        validBetsList
      );
      const params = buildParams(betsToSend, state);
      actions.push(betActions.bet.request(params));
      actions.push(socketActions.socket.send(params));
    }

    if (currentBetsList.length > validBetsList.length) {
      actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));

      const totalBet = getTotalBet(validBetsList);
      const validBets = validBetsList.reduce((acc, b) => ({ ...acc, [b.index]: b, }), {});
      actions.push(betActions.history.apply({ current: validBets, }));
      actions.push(betActions.totalBet.set({ value: totalBet, }));
    }
  }

  return actions;
};
