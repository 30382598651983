import { createActions, } from 'redux-actions';

export default createActions({
  BET: {
    REQUEST: null,
    SUCCESS: null,
    FAILURE: null,
    ADD: null,
    SET: null,
    RESET: null,
    CLEAR: null,
    CACHE: null,
    REBET: null,
    APPLY: null,
    DOUBLE: null,
  },
  SIMPLE_BET: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  NEIGHBOR_BET: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  FRENCH_BET: {
    ADD: null,
    SET: null,
    RESET: null,
  },
  TOTAL_BET: {
    SET: null,
  },
  HISTORY: {
    POP: null,
    RESET: null,
    APPLY: null,
  },
});
