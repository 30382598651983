import {
  prop, compose, map, values,
} from 'ramda';

export const clamp = (numbers) => (p) => {
  const high = compose(
    (percs) => Math.max(...percs),
    map(prop('percent')),
    values
  )(numbers);

  return (p / high) * 100;
};
