import {
  map, flatMap, filter, pluck, distinctUntilChanged, skipUntil, take,
} from 'rxjs/operators';
import { of, concat, } from 'rxjs';
import {
  map as rMap, equals, length, isEmpty,
} from 'ramda';
import { ofType, combineEpics, } from 'redux-observable';
import { GAME_RESULT, NO_MORE_BETS, PLACE_YOUR_BETS, } from '@ezugi/constants';
import { actions as bootstrapActions, } from '@ezugi/bootstrap';
import betActions from '../../actions/bets';
import { currentBetsSelector, betsSelector, } from '../../selectors';

import {
  validateSimpleBets,
  validateNeighborBets,
  validateFrenchBets,
  validateBetUndo,
  validateRebet,
  validateDouble,
} from './utils/validations';
import { createBetRequestPayload, } from './utils/request';
import { getTotalBet, } from './utils/core';

const {
  roundActions: { round, },
} = bootstrapActions;
const { bet, simpleBet, neighborBet, frenchBet, totalBet, history, } = betActions;

const betResetEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === GAME_RESULT || roundStatus === PLACE_YOUR_BETS),
  flatMap(() => concat(of(history.reset()), of(bet.reset())))
);

const simpleBetEpic = (action$, state$) => action$.pipe(
  ofType(simpleBet.add),
  pluck('payload'),
  map((bets) => validateSimpleBets(bets, state$.value)),
  flatMap(({ bets, valid, initialBets, ...result }) => {
    // add valid bets
    const actions = [ valid && bets && !isEmpty(bets) ? of(bet.set(bets)) : [], ];

    // add simple bet
    if (valid) {
      const isGroupBet = bets && Object.keys(bets).length > 1;
      !isGroupBet && actions.push(of(simpleBet.set(bets)));
    }

    // add other actions
    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const neighborBetEpic = (action$, state$) => action$.pipe(
  ofType(neighborBet.add),
  pluck('payload'),
  map((bets) => validateNeighborBets(bets, state$.value)),
  flatMap(({ valid, bets, initialBets, ...result }) => {
    // add valid bets
    const actions = [ valid && bets && !isEmpty(bets) ? of(bet.set(bets)) : [], ];

    // add neighbor bets
    if (valid) {
      const isGroupBet = bets && Object.keys(bets).length > 1;
      isGroupBet && actions.push(of(neighborBet.set(initialBets)));
    }

    // add other actions
    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const frenchBetEpic = (action$, state$) => action$.pipe(
  ofType(frenchBet.add),
  pluck('payload'),
  map((bets) => validateFrenchBets(bets, state$.value)),
  flatMap(({ bets, initialBets, valid, ...result }) => {
    // add valid bets
    const actions = [ valid && bets && !isEmpty(bets) ? of(bet.set(bets)) : [], ];

    // add neighbor bets
    if (valid) {
      const isGroupBet = bets && Object.keys(bets).length > 1;
      isGroupBet && actions.push(of(frenchBet.set(initialBets)));
    }

    // add other actions
    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const totalBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.set),
  map(() => {
    const currentBets = currentBetsSelector(state$.value);

    return totalBet.set({ value: getTotalBet(currentBets), });
  })
);

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  map(() => createBetRequestPayload(state$.value)),
  filter(length),
  flatMap((actions) => {
    const { history: h, last, ...rest } = betsSelector(state$.value);
    return concat(...rMap(of, actions), of(betActions.bet.success()), of(betActions.bet.cache(rest)));
  })
);

const betUndoEpic = (action$, state$) => action$.pipe(
  ofType(history.pop),
  map(() => validateBetUndo(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const rebetEpic = (action$, state$) => action$.pipe(
  ofType(bet.rebet),
  map(() => validateRebet(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const doubleEpic = (action$, state$) => action$.pipe(
  ofType(bet.double),
  map(() => validateDouble(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

export default combineEpics(
  simpleBetEpic,
  betResetEpic,
  neighborBetEpic,
  frenchBetEpic,
  betUndoEpic,
  rebetEpic,
  doubleEpic,
  betRequestEpic,
  totalBetEpic
);
