/* eslint-disable */

export const RACETRACK_BETS = [
  100, 132, 115, 119, 104, 121, 102, 125, 117, 134, 106, 127, 113,
  136, 111, 130, 108, 123, 110, 105, 124, 116, 133, 101, 120, 114,
  131, 109, 122, 118, 129, 107, 128, 112, 135, 103, 126,
];

export const AMERICAN_RACETRACK_BETS = [
  100, 128, 109, 126, 130, 111, 107, 120, 132, 117, 105, 122, 134,
  115, 103, 124, 136, 113, 101, 137, 127, 110, 125, 129, 112, 108,
  119, 131, 118, 106, 121, 133, 116, 104, 123, 135, 114, 102
];
/* eslint-enable */

export const GROUP_BETS = {
  1000: [
    { multiplier: 1, index: 226, }, // tiers
    { multiplier: 1, index: 239, }, // 5/8, 10/11, 13/16, 23/24, 27/30, 33/36
    { multiplier: 1, index: 253, },
    { multiplier: 1, index: 219, },
    { multiplier: 1, index: 209, },
    { multiplier: 1, index: 211, },
  ],
  1001: [
    { multiplier: 1, index: 101, }, // orpheline
    { multiplier: 1, index: 202, },
    { multiplier: 1, index: 229, },
    { multiplier: 1, index: 230, },
    { multiplier: 1, index: 259, },
  ],
  1002: [
    { multiplier: 2, index: 300, }, // voisins
    { multiplier: 2, index: 419, },
    { multiplier: 1, index: 250, },
    { multiplier: 1, index: 204, },
    { multiplier: 1, index: 206, },
    { multiplier: 1, index: 255, },
    { multiplier: 1, index: 235, },
  ],
  1003: [
    { multiplier: 1, index: 126, }, // Jeu Zero
    { multiplier: 1, index: 200, },
    { multiplier: 1, index: 204, },
    { multiplier: 1, index: 235, },
  ],
};

export const DEFAULT_NEIGHBOURS = 2;
export const NEIGHBOURS_NUMBER_KEY = 'NEIGHBOURS_NUMBER';
