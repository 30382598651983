import { css, } from 'emotion';
import { SIZES, } from '@ezugi/constants';
import { keyframes, } from 'react-emotion';

/* ANIMATIONS */
export const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const sixteenByNineClassName = css`
  display: flex;
  justify-content: center;
  align-items: center;

  body {
    width: 1440px;
    height: 810px;
    margin: 0 auto;
  }
`;

export const gameResultsClassName = css`
  @media (orientation: portrait) {
    top: ${SIZES.APPBAR_PORTRAIT_HEIGHT}px;
    height: calc(70% - ${SIZES.APPBAR_PORTRAIT_HEIGHT}px);
    position: absolute;
  }
  @media (orientation: landscape) {
    [data-name='winner-list'] {
      top: ${SIZES.APPBAR_LANDSCAPE_HEIGHT}px;
    }
  }
`;
