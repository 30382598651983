import { concat, of, } from 'rxjs';
import {
  pluck, filter, map, flatMap,
} from 'rxjs/operators';
import { ofType, combineEpics, } from 'redux-observable';
import { PLACE_YOUR_BETS, NO_MORE_BETS, GAME_RESULT, } from '@ezugi/constants';

import { actions, } from '@ezugi/bootstrap';

import { isGameStartedSelector, isAutoRouletteSelector, } from '../../selectors';

const { roundActions: { round, }, soundActions: { sound, }, } = actions;

const placeYourBetsEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  filter(() => isGameStartedSelector(state$.value)),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === PLACE_YOUR_BETS && isAutoRouletteSelector(state$.value)),
  map(() => sound.play({ soundName: 'place_your_bets', spriteName: 'auto_roulette_sprite', queueMode: true, }))
);

const noMoreBetsEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  filter(() => isGameStartedSelector(state$.value)),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === NO_MORE_BETS && isAutoRouletteSelector(state$.value)),
  map(() => sound.play({ soundName: 'no_more_bets', spriteName: 'auto_roulette_sprite', queueMode: true, }))
);

const winningNumberEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  filter(() => isGameStartedSelector(state$.value)),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === GAME_RESULT && isAutoRouletteSelector(state$.value)),
  flatMap(({ winningNumber: { WinningNumber, }, }) => concat(
    of(
      sound.play({
        soundName: 'the_winning_number_is',
        spriteName: 'auto_roulette_sprite',
        queueMode: true,
      })
    ),
    of(
      sound.play({
        soundName: `${WinningNumber}`,
        spriteName: 'auto_roulette_sprite',
        queueMode: true,
      })
    )
  ))
);

export default combineEpics(placeYourBetsEpic, noMoreBetsEpic, winningNumberEpic);
