import { handleActions, } from 'redux-actions';
import { mergeWith, } from 'ramda';

import betActions from '../actions/bets';
import { getTotalBet, } from '../epics/bets/utils/core';

const { bet, simpleBet, neighborBet, frenchBet, totalBet, history, } = betActions;

const addValues = (a, b) => ({ ...a, ...b, value: +(a.value + b.value).toFixed(2), });
export const INITIAL_STATE = {
  current: {},
  simple: {},
  neighbors: {},
  french: {},
  totalBet: 0,
};

INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    // eslint-disable-next-line no-shadow
    [bet.set]: ({ history, ...state }, { payload, }) => {
      const current = mergeWith(addValues, state.current, payload);
      const t = getTotalBet(current);
      return {
        ...state,
        current,
        history: [ ...history, { ...state, current, totalBet: t, }, ],
      };
    },
    [bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [bet.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
      history: [ ...state.history, payload, ],
    }),
    [simpleBet.set]: (state, { payload, }) => {
      const simple = mergeWith(addValues, state.simple, payload);
      return {
        ...state,
        simple,
      };
    },
    [neighborBet.set]: (state, { payload, }) => {
      const neighbors = mergeWith(addValues, state.neighbors, payload);
      return {
        ...state,
        neighbors,
      };
    },
    [frenchBet.set]: (state, { payload, }) => {
      const french = mergeWith(addValues, state.french, payload);
      return {
        ...state,
        french,
      };
    },
    [history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, }, ],
    }),
    [history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [totalBet.set]: (state, { payload: { value, }, }) => ({
      ...state,
      totalBet: value,
    }),
  },
  INITIAL_STATE
);
