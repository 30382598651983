import { createActions, } from 'redux-actions';

export default createActions({
  SOCKET: {
    REQUEST: null,
    SUCCESS: null,
    FAILURE: null,
    ERROR: null,
    SET: null,
    CLOSE: null,
  },
});
