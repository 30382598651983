export const ODD = 'odd';
export const EVEN = 'even';
export const ZERO = 'zero';
export const DOUBLE_ZERO = 'double_zero';
export const LOW = 'low';
export const HIGH = 'high';

// All the bet types available in roulette
export const SINGLE = 'SINGLE';
export const SPLIT = 'SPLIT';
export const STREET = 'STREET'; // trio
export const COLUMN = 'COLUMN';
export const CORNER = 'CORNER';
export const SIX_LINE = 'SIX_LINE';
export const BASKET_BET = 'BASKET_BET';
export const DOZEN = 'DOZEN'; // 1-18/Even/Red/Black/odd/19-36

// Racetrack bets
export const VOISINS = 'VOISINS';
export const JEU_ZERO = 'JEU_ZERO';
export const TIERS = 'TIERS';
export const ORPHELINS = 'ORPHELINS';

// Special bets
export const SNAKE_BET = 'SNAKE_BET';
export const SPLITS = 'SPLITS';
export const FINALS = 'FINALS';
export const MAXIMUM_BET = 'MAXIMUM_BET';
export const COMPLETE_NUMBER = 'COMPLETE_NUMBER';

export const RED = 'RED';
export const BLACK = 'BLACK';
export const GREEN = 'GREEN';

// Used for the limits and payouts display
export const RED_BLACK = 'Red_Black';
export const EVEN_ODD = 'Even_Odd';
export const HIGH_LOW = '1-18/19-36';

export const JACKPOT = 'Jackpot';

export const BET_TYPES = {
  1: { label: 'round_details.details_single_bet', type: SINGLE, },
  2: { label: 'round_details.details_split_bet', type: SPLIT, },
  3: { label: 'round_details.details_street_bet', type: STREET, },
  4: { label: 'round_details.details_corner_bet', type: CORNER, },
  5: { label: 'round_details.details_street_bet', type: STREET, },
  6: { label: 'round_details.details_six_line_bet', type: SIX_LINE, },
  7: { label: 'round_details.details_column_bet', type: COLUMN, },
  8: { label: 'round_details.details_dozen_bet', type: DOZEN, },
  900: { label: 'round_details.details_low_bet', type: LOW, },
  901: { label: 'round_details.details_even_bet', type: EVEN, },
  902: { label: 'round_details.details_black_bet', type: BLACK, },
  903: { label: 'round_details.details_red_bet', type: RED, },
  904: { label: 'round_details.details_odd_bet', type: ODD, },
  905: { label: 'round_details.details_high_bet', type: HIGH, },
  [JACKPOT]: { label: 'round_details.details_jackpot', type: JACKPOT, },
};

/* eslint-disable max-len */

// indexes by index
export const BET_MATCHES = {
  // single
  'index-100': [ 100, ],
  'index-101': [ 101, ],
  'index-102': [ 102, ],
  'index-103': [ 103, ],
  'index-104': [ 104, ],
  'index-105': [ 105, ],
  'index-106': [ 106, ],
  'index-107': [ 107, ],
  'index-108': [ 108, ],
  'index-109': [ 109, ],
  'index-110': [ 110, ],
  'index-111': [ 111, ],
  'index-112': [ 112, ],
  'index-113': [ 113, ],
  'index-114': [ 114, ],
  'index-115': [ 115, ],
  'index-116': [ 116, ],
  'index-117': [ 117, ],
  'index-118': [ 118, ],
  'index-119': [ 119, ],
  'index-120': [ 120, ],
  'index-121': [ 121, ],
  'index-122': [ 122, ],
  'index-123': [ 123, ],
  'index-124': [ 124, ],
  'index-125': [ 125, ],
  'index-126': [ 126, ],
  'index-127': [ 127, ],
  'index-128': [ 128, ],
  'index-129': [ 129, ],
  'index-130': [ 130, ],
  'index-131': [ 131, ],
  'index-132': [ 132, ],
  'index-133': [ 133, ],
  'index-134': [ 134, ],
  'index-135': [ 135, ],
  'index-136': [ 136, ],
  'index-137': [ 137, ],
  // split
  'index-200': [ 100, 103, ], // not in american
  'index-201': [ 103, 106, ],
  'index-202': [ 106, 109, ],
  'index-203': [ 109, 112, ],
  'index-204': [ 112, 115, ],
  'index-205': [ 115, 118, ],
  'index-206': [ 118, 121, ],
  'index-207': [ 121, 124, ],
  'index-208': [ 124, 127, ],
  'index-209': [ 127, 130, ],
  'index-210': [ 130, 133, ],
  'index-211': [ 133, 136, ],
  'index-212': [ 102, 103, ],
  'index-213': [ 105, 106, ],
  'index-214': [ 108, 109, ],
  'index-215': [ 111, 112, ],
  'index-216': [ 114, 115, ],
  'index-217': [ 117, 118, ],
  'index-218': [ 120, 121, ],
  'index-219': [ 123, 124, ],
  'index-220': [ 126, 127, ],
  'index-221': [ 129, 130, ],
  'index-222': [ 132, 133, ],
  'index-223': [ 135, 136, ],
  'index-224': [ 100, 102, ], // not in american
  'index-225': [ 102, 105, ],
  'index-226': [ 105, 108, ],
  'index-227': [ 108, 111, ],
  'index-228': [ 111, 114, ],
  'index-229': [ 114, 117, ],
  'index-230': [ 117, 120, ],
  'index-231': [ 120, 123, ],
  'index-232': [ 123, 126, ],
  'index-233': [ 126, 129, ],
  'index-234': [ 129, 132, ],
  'index-235': [ 132, 135, ],
  'index-236': [ 101, 102, ],
  'index-237': [ 104, 105, ],
  'index-238': [ 107, 108, ],
  'index-239': [ 110, 111, ],
  'index-240': [ 113, 114, ],
  'index-241': [ 116, 117, ],
  'index-242': [ 119, 120, ],
  'index-243': [ 122, 123, ],
  'index-244': [ 125, 126, ],
  'index-245': [ 128, 129, ],
  'index-246': [ 131, 132, ],
  'index-247': [ 134, 135, ],
  'index-248': [ 100, 101, ], // not in american
  'index-249': [ 101, 104, ],
  'index-250': [ 104, 107, ],
  'index-251': [ 107, 110, ],
  'index-252': [ 110, 113, ],
  'index-253': [ 113, 116, ],
  'index-254': [ 116, 119, ],
  'index-255': [ 119, 122, ],
  'index-256': [ 122, 125, ],
  'index-257': [ 125, 128, ],
  'index-258': [ 128, 131, ],
  'index-259': [ 131, 134, ],
  'index-290': [ 100, 137, ], // american!
  'index-291': [ 103, 137, ], // american! (replaces 200)
  'index-292': [ 101, 100, ], // american! (replaces 248)
  // trio
  'index-300': [ 100, 102, 103, ], // not in american
  'index-301': [ 100, 101, 102, ], // not in american
  // street
  'index-500': [ 101, 102, 103, ],
  'index-501': [ 104, 105, 106, ],
  'index-502': [ 107, 108, 109, ],
  'index-503': [ 110, 111, 112, ],
  'index-504': [ 113, 114, 115, ],
  'index-505': [ 116, 117, 118, ],
  'index-506': [ 119, 120, 121, ],
  'index-507': [ 122, 123, 124, ],
  'index-508': [ 125, 126, 127, ],
  'index-509': [ 128, 129, 130, ],
  'index-510': [ 131, 132, 133, ],
  'index-511': [ 134, 135, 136, ],
  'index-590': [ 100, 137, 102, ], // american! (replaces 224)
  'index-591': [ 100, 101, 102, ], // american! (replaces 301)
  'index-592': [ 137, 102, 103, ], // american! (replaces 300)
  // corners
  'index-400': [ 102, 103, 105, 106, ],
  'index-401': [ 105, 106, 108, 109, ],
  'index-402': [ 108, 109, 111, 112, ],
  'index-403': [ 111, 112, 114, 115, ],
  'index-404': [ 114, 115, 117, 118, ],
  'index-405': [ 117, 118, 120, 121, ],
  'index-406': [ 120, 121, 123, 124, ],
  'index-407': [ 123, 124, 126, 127, ],
  'index-408': [ 126, 127, 129, 130, ],
  'index-409': [ 129, 130, 132, 133, ],
  'index-410': [ 132, 133, 135, 136, ],
  'index-411': [ 101, 102, 104, 105, ],
  'index-412': [ 104, 105, 107, 108, ],
  'index-413': [ 107, 108, 110, 111, ],
  'index-414': [ 110, 111, 113, 114, ],
  'index-415': [ 113, 114, 116, 117, ],
  'index-416': [ 116, 117, 119, 120, ],
  'index-417': [ 119, 120, 122, 123, ],
  'index-418': [ 122, 123, 125, 126, ],
  'index-419': [ 125, 126, 128, 129, ],
  'index-420': [ 128, 129, 131, 132, ],
  'index-421': [ 131, 132, 134, 135, ],
  'index-422': [ 100, 101, 102, 103, ], // not in american
  // sixline
  'index-600': [ 101, 102, 103, 104, 105, 106, ],
  'index-601': [ 104, 105, 106, 107, 108, 109, ],
  'index-602': [ 107, 108, 109, 110, 111, 112, ],
  'index-603': [ 110, 111, 112, 113, 114, 115, ],
  'index-604': [ 113, 114, 115, 116, 117, 118, ],
  'index-605': [ 116, 117, 118, 119, 120, 121, ],
  'index-606': [ 119, 120, 121, 122, 123, 124, ],
  'index-607': [ 122, 123, 124, 125, 126, 127, ],
  'index-608': [ 125, 126, 127, 128, 129, 130, ],
  'index-609': [ 128, 129, 130, 131, 132, 133, ],
  'index-610': [ 131, 132, 133, 134, 135, 136, ],
  'index-690': [ 100, 137, 101, 102, 103, ], // american! (replaces 422)
  // columns
  'index-700': [ 700, 103, 106, 109, 112, 115, 118, 121, 124, 127, 130, 133, 136, ], // 3rd
  'index-701': [ 701, 102, 105, 108, 111, 114, 117, 120, 123, 126, 129, 132, 135, ], // 2nd
  'index-702': [ 702, 101, 104, 107, 110, 113, 116, 119, 122, 125, 128, 131, 134, ], // 1st
  // dozens
  'index-800': [ 800, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, ], // 1st
  'index-801': [ 801, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, ], // 2nd
  'index-802': [ 802, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, ], // 3rd
  // outside bets
  'index-900': [ 900, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, ], // low
  'index-901': [ 901, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120, 122, 124, 126, 128, 130, 132, 134, 136, ], // even
  'index-902': [ 902, 102, 104, 106, 108, 110, 111, 113, 115, 117, 120, 122, 124, 126, 128, 129, 131, 133, 135, ], // black
  'index-903': [ 903, 101, 103, 105, 107, 109, 112, 114, 116, 118, 119, 121, 123, 125, 127, 130, 132, 134, 136, ], // red
  'index-904': [ 904, 101, 103, 105, 107, 109, 111, 113, 115, 117, 119, 121, 123, 125, 127, 129, 131, 133, 135, ], // odd
  'index-905': [ 905, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, ], // high,
};
