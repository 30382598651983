import { handleActions, } from 'redux-actions';

import statisticsActions from '../actions/statistics';

const { statistics, } = statisticsActions;

export default handleActions(
  {
    [statistics.set]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
  },
  {}
);
