/* eslint-disable max-len */
import { add, reduce, } from 'ramda';

import {
  RACETRACK_BETS, AMERICAN_RACETRACK_BETS, DEFAULT_NEIGHBOURS, GROUP_BETS,
} from './constants';

export const isNeighborBet = (index) => index > 10000;

export const isGroupBet = (index) => index >= 1000 && !isNeighborBet(index);

// Build the neighbor index,
// 1st number is always 1, followed by the index, ending with the number of selected neigbors
export const buildNeighborBetIndex = ({ index, nrOfNeighbours = DEFAULT_NEIGHBOURS, }) => Number(`1${index}${nrOfNeighbours}`);

export const getNeighborBetsParams = (american) => (_index) => {
  const string = `${_index}`;
  const index = Number(string.substr(1, 3));
  const nrOfNeighbours = Number(string.substr(4));

  return {
    index,
    nrOfNeighbours,
    american,
  };
};

export const getFrenchBets = (_index) => GROUP_BETS[_index].map(({ index, }) => index);

export const getFrenchBetTotal = ({ index, value, }) => {
  const bets = GROUP_BETS[index];
  const values = bets.map(({ multiplier, }) => multiplier * value);
  return reduce(add, 0, values);
};

export const getNeighborBets = ({ index, nrOfNeighbours = DEFAULT_NEIGHBOURS, american = false, }) => {
  const BETS = american ? AMERICAN_RACETRACK_BETS : RACETRACK_BETS;
  const middleIndex = BETS.indexOf(index);
  const numberOfBets = 2 * nrOfNeighbours + 1;
  const result = [];

  let startIndex = middleIndex - nrOfNeighbours;
  if (startIndex < 0) {
    startIndex = BETS.length + startIndex;
  }

  for (let i = 0; i < numberOfBets; i++) {
    if (startIndex === BETS.length) {
      startIndex = 0;
    }

    result.push(BETS[startIndex]);
    startIndex++;
  }

  return result;
};
