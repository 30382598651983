/* eslint-disable radix, eqeqeq */
import {
  sum,
  pathOr,
  path,
  prop,
  propOr,
  contains,
  not,
  compose,
  sortWith,
  descend,
  reverse,
  when,
  filter,
  values,
  isNil,
  last,
  isEmpty,
  omit,
  slice,
} from 'ramda';
import { createSelector, } from 'reselect';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';
import { NUMBERS, AMERICAN_NUMBERS, } from '../../components/StatsWheel/constants';
import { clamp, } from '../../components/StatsWheel/utils';

export const configSelector = propOr({}, 'config');

export const winnerListConfigSelector = createSelector(
  configSelector,
  prop('winnerList')
);
export const definitionsSelector = createSelector(
  configSelector,
  prop('definitions')
);
export const preloaderSelector = createSelector(
  configSelector,
  prop('preloader')
);
export const generalConfigSelector = createSelector(
  configSelector,
  prop('general')
);
export const widgetsSelector = createSelector(
  configSelector,
  prop('widgets')
);
export const skinSelector = createSelector(
  configSelector,
  prop('skin')
);
export const formatSelector = createSelector(
  configSelector,
  prop('format')
);
export const authSelector = createSelector(
  configSelector,
  prop('authParams')
);
export const cashierSelector = createSelector(
  configSelector,
  prop('cashier')
);
export const chatConfigSelector = createSelector(
  configSelector,
  prop('chat')
);
export const rouletteConfigSelector = createSelector(
  configSelector,
  prop('roulette')
);
export const gameTypesSelector = createSelector(
  configSelector,
  prop('gameTypes')
);
export const tipsSelector = prop('tips');

export const userSelector = prop('user');
export const userNicknameSelector = path([ 'user', 'nickname', ]);
export const currencySelector = createSelector(
  userSelector,
  prop('currency')
);
export const userIdSelector = createSelector(
  userSelector,
  prop('uid')
);

export const languagesSelector = createSelector(
  generalConfigSelector,
  prop('availableLanguages')
);

export const currentLanguageSelector = createSelector(
  generalConfigSelector,
  languagesSelector,
  // eslint-disable-next-line
  (generalConfig, languages) => languages.find(({ code, }) => code === generalConfig.language) || languages.find(({ code, }) => code === 'en')
);

export const playerHistorySelector = propOr({}, 'history');

export const tableSelector = propOr({}, 'table');
export const tableIdSelector = path([ 'table', 'id', ]);
export const tableNameSelector = path([ 'table', 'title', ]);
export const tableLimitsSelector = path([ 'table', 'limits', ]);
export const lobbySelector = propOr({}, 'lobby');

export const chatSelector = propOr({}, 'chat');
export const isChatMutedSelector = createSelector(
  chatSelector,
  prop('mute')
);
export const roomMessagesSelector = createSelector(
  chatSelector,
  userSelector,
  (chat, user) => compose(
    when(() => chat.mute, filter(({ name, }) => name === user.nickname)),
    pathOr([], [ 'room', 'messages', ])
  )(chat)
);
export const supportMessagesSelector = createSelector(
  chatSelector,
  path([ 'support', 'messages', ])
);

// Default currency to $
// TODO: check if this default should apply
export const userCurrencySelector = pathOr('$', [ 'user', 'currency', ]);

export const userBalanceSelector = pathOr(0, [ 'user', 'balance', ]);

/**
 * Contains all game relevant ids such as gameId, limitId, tableId
 */
export const gameSelector = pathOr({}, [ 'game', ]);
export const gameIdSelector = createSelector(
  gameSelector,
  prop('gameId')
);
export const gameNameSelector = createSelector(
  gameSelector,
  prop('name')
);
export const isGameStartedSelector = createSelector(
  gameSelector,
  prop('started')
);

export const isOTTRouletteSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 29 || gameId == 35
);

export const isAmericanRouletteSelector = createSelector(
  gameIdSelector,
  (gameId) => gameId == 31 || gameId == 35 || gameId == 32
);

export const isAutoRouletteSelector = createSelector(
  gameIdSelector,
  generalConfigSelector,
  (gameId, generalConfig) => (gameId == 7 || gameId == 32) && generalConfig.enableAutoSounds
);

/**
 * Selector for the current limits of the table
 */
export const currentLimitsSelector = createSelector(
  tableLimitsSelector,
  gameSelector,
  (limits, game) => limits[game.limitId] || Object.values(limits)[0]
);

export const minBetSelector = createSelector(
  currentLimitsSelector,
  propOr(0, 'Min_Bet')
);

/**
 * Selector for the chips based on current limits
 * Display only the first 7 chips
 */
export const chipsSelector = createSelector(
  currentLimitsSelector,
  (limits) => limits.chips.slice(0, 7)
);

export const validatedChipsSelector = createSelector(
  // eslint hax
  chipsSelector,
  userBalanceSelector,
  (chips, balance) => chips.map((chip) => ({
    ...chip,
    disabled: balance < chip.value,
  }))
);
export const selectedChipSelector = createSelector(
  gameSelector,
  (game) => game.selectedChip
);

export const dealerNameSelector = createSelector(
  tableSelector,
  (table) => table.dealer_name
);

export const miniLobbyOpenSelector = createSelector(
  lobbySelector,
  (lobby) => lobby.open
);

/**
 * Select logo based on BO config
 */
export const logoUrlSelector = createSelector(
  definitionsSelector,
  generalConfigSelector,
  (definitions, general) => (general.enableLogo ? `${definitions.OPERATOR_ASSETS}${general.logoUrl}` : '')
);
export const nameBeforeGreetingSelector = pathOr(false, [ 'config', 'general', 'nameBeforeGreetingMessage', ]);

export const roundSelector = path([ 'round', ]);
export const roundIdSelector = path([ 'round', 'roundId', ]);
export const roundStatusSelector = pathOr('', [ 'round', 'roundStatus', ]);
export const winAmountSelector = createSelector(
  roundSelector,
  (round) => round.winAmount
);

/* Do not show timer on OTT or american OTT roulette */
export const showTimerSelector = createSelector(
  gameIdSelector,
  roundSelector,
  (gameId, { totalTime, }) => gameId != 29 && gameId != 35 && totalTime != 0
);

export const betsSelector = prop('bets');
export const currentBetsSelector = createSelector(
  path([ 'bets', 'current', ]),
  omit([ 'Jackpot', ])
);
export const lastBetsSelector = path([ 'bets', 'last', ]);
export const betsHistorySelector = path([ 'bets', 'history', ]);
export const neighborBetsSelector = path([ 'bets', 'neighbors', ]);
export const frenchBetsSelector = path([ 'bets', 'french', ]);
export const totalBetSelector = path([ 'bets', 'totalBet', ]);

export const lastWinSelector = pathOr(0, [ 'game', 'lastWin', ]);
export const winningNumberSelector = compose(
  prop('WinningNumber'),
  pathOr({}, [ 'round', 'winningNumber', ])
);

export const statisticsSelector = propOr({}, [ 'statistics', ]);
export const statisticsActiveNumberSelector = createSelector(
  statisticsSelector,
  propOr(null, 'activeNumber')
);
export const gameResultsSelector = createSelector(
  statisticsSelector,
  propOr([], 'gameResults')
);
export const lastWinningNumberSelector = createSelector(
  gameResultsSelector,
  (results) => last(results).WinningNumber
);
export const groupStatisticsSelector = createSelector(
  statisticsSelector,
  isAmericanRouletteSelector,
  ({ group, percentages, }, american) => {
    const Zero = (percentages[0] || {}).percent;
    const DoubleZero = (percentages[37] || {}).percent;

    const results = {
      ...group,
      ...(isNil(Zero) ? { Zero: 0, } : { Zero, }),
      ...(isNil(DoubleZero) ? { DoubleZero: 0, } : { DoubleZero, }),
    };

    !american && delete results.DoubleZero;

    const total = compose(
      sum,
      values
    )(results);

    return total ? results : {};
  }
);
export const percentagesStatisticsSelector = createSelector(
  statisticsSelector,
  propOr({}, 'percentages')
);
export const hotAndColdNumbersSelector = (state, { type, }) => state.statistics[`${type}Numbers`];
export const lastSpinsSelector = createSelector(
  gameResultsSelector,
  reverse
);

const getCount = (results) => ({ number: no, }) => results.filter(({ WinningNumber, }) => WinningNumber === no).length;
export const statsWheelNumbersSelector = createSelector(
  percentagesStatisticsSelector,
  gameResultsSelector,
  isAmericanRouletteSelector,
  (percentages, results, isAmerican) => {
    const getResultsCount = getCount(results);
    const clampPercent = clamp(percentages);

    return (isAmerican ? AMERICAN_NUMBERS : NUMBERS).map((n) => {
      const { percent, } = percentages[n.number] || { percent: 0, };
      return { ...n, percent: clampPercent(percent), count: getResultsCount(n), };
    });
  }
);

const sortByWinAmount = sortWith([ descend(prop('WinAmount')), ]);
export const winnerListSelector = pathOr([], [ 'round', 'winnerList', ]);
export const sortedWinnerListSelector = createSelector(
  winnerListSelector,
  sortByWinAmount
);

export const volumeSelector = path([ 'media', 'volume', ]);
export const gameSoundsSelector = path([ 'media', 'gameSounds', ]);

export const settingsSelector = createSelector(
  gameSoundsSelector,
  isChatMutedSelector,
  (gameSounds, hideOthersChat) => ({
    gameSounds,
    hideOthersChat,
  })
);

export const userCanChatSelector = createSelector(
  userBalanceSelector,
  minBetSelector,
  (balance, minBet) => balance >= minBet
);

const canPlaceBets = (status) => contains(status, [ PLACE_YOUR_BETS, LAST_BETS, ]);
export const canPlaceBetsSelector = createSelector(
  roundStatusSelector,
  canPlaceBets
);

export const canBetOnStatisticsSelector = createSelector(
  generalConfigSelector,
  canPlaceBetsSelector,
  (generalConfig, userCanBet) => userCanBet && generalConfig.enableStatisticsBetting
);

export const idleGameBoardSelector = createSelector(
  canPlaceBetsSelector,
  not
);
export const videoConfigSelector = createSelector(
  authSelector,
  definitionsSelector,
  userSelector,
  (auth, definitions, user) => ({
    operatorId: parseInt(auth.operatorId),
    gameId: parseInt(auth.game_id),
    tableId: parseInt(auth.table_id),
    identifier: auth.identifier,
    cdnLocation: definitions.VIDEO_CDN_SERVICE,
    playerId: parseInt(user.sessionId),
  })
);

export const userHasBetsSelector = compose(
  not,
  isEmpty,
  currentBetsSelector
);

export const previousSpinsStackSelector = compose(
  slice(0, 10),
  reverse,
  gameResultsSelector
);
