/* eslint-disable camelcase */

import {
  contains,
  reduce,
  prop,
  all,
  clamp,
  merge,
  compose,
  map,
  reject,
  isNil,
  flatten,
  values,
  head,
  evolve,
  xprod,
  converge,
  last,
  isEmpty,
  unless,
  has,
} from 'ramda';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';
import { actions as bootstrapActions, constants, selectors, } from '@ezugi/bootstrap';
import { STATUS, } from '../constants';

import { INITIAL_STATE, } from '../../../reducers/bets';
import {
  currentLimitsSelector,
  currentBetsSelector,
  betsSelector,
  lastBetsSelector,
  userBalanceSelector,
  totalBetSelector,
  generalConfigSelector,
  rouletteConfigSelector,
  isAmericanRouletteSelector,
} from '../../../selectors';

import betActions from '../../../actions/bets';

import { getTotalBet, getCoveredNumbers, } from './core';
import { getNeighborBets, getNeighborBetsParams, } from '../../../../components/Racetrack/utils';
import { GROUP_BETS, } from '../../../../components/Racetrack/constants';

const { shouldShowJackpotSelector, jackpotBetValueSelector, } = selectors;

const {
  DIALOG: { LOW_BALANCE_DIALOG, },
} = constants;
const { notificationActions, dialogActions, } = bootstrapActions;
const RESULT_SEED = { ok: true, valid: true, status: STATUS.VALID, };
const statusPriorityMap = {
  [STATUS.BETTING_NOT_ALLOWED]: 5,
  [STATUS.NOT_ALLOWED]: 4,
  [STATUS.INVALID_BALANCE]: 3,
  [STATUS.OVER_INDEX_LIMIT]: 2,
  [STATUS.OVER_TABLE_LIMIT]: 2,
  [STATUS.BELOW_INDEX_LIMIT]: 1,
  [STATUS.BELOW_TABLE_LIMIT]: 1,
  [STATUS.VALID]: 0,
};

export const convertNeighborsToStraights = (american) => compose(
  reduce(
    (acc, [ index, value, ]) => ({
      ...acc,
      [index]: { index, value, },
    }),
    {}
  ),
  converge(xprod, [ prop('index'), prop('value'), ]),
  evolve({
    index: compose(
      getNeighborBets,
      getNeighborBetsParams(american)
    ),
    value: Array.of,
  }),
  head,
  values
);

export const convertFrenchToSimple = compose(
  ({ index, value, }) => {
    const simpleBetsParams = GROUP_BETS[index];
    return reduce(
      /* eslint-disable-next-line no-shadow */
      (acc, { multiplier, index, }) => ({
        ...acc,
        [index]: { index, value: value * multiplier, },
      }),
      {},
      simpleBetsParams
    );
  },
  head,
  values
);

export const getIndexLimits = (() => {
  const cache = {};
  let currentLimits;

  return (index, state) => {
    if (cache[index]) {
      return cache[index];
    }

    currentLimits = currentLimits || currentLimitsSelector(state);

    const limits = { min: 0, max: 0, };
    if (index >= 100 && index < 200) {
      // straight up
      limits.min = currentLimits.Min_StraightUpBet;
      limits.max = currentLimits.Max_StraightUpBet;
    } else if (index >= 200 && index < 300) {
      // split
      limits.min = currentLimits.Min_SplitBet;
      limits.max = currentLimits.Max_SplitBet;
    } else if (index >= 300 && index < 400) {
      // trio (same as street)
      limits.min = currentLimits.Min_StreetBet;
      limits.max = currentLimits.Max_StreetBet;
    } else if (index >= 400 && index < 500) {
      // corner
      limits.min = currentLimits.Min_CornerBet;
      limits.max = currentLimits.Max_CornerBet;
    } else if (index >= 500 && index < 600) {
      // street
      limits.min = currentLimits.Min_StreetBet;
      limits.max = currentLimits.Max_StreetBet;
    } else if (index >= 600 && index < 700) {
      // sixline
      limits.min = currentLimits.Min_LineBet;
      limits.max = currentLimits.Max_LineBet;
    } else if (index >= 700 && index < 800) {
      // columns
      limits.min = currentLimits.Min_Dozen_ColumnBet;
      limits.max = currentLimits.Max_Dozen_ColumnBet;
    } else if (index >= 800 && index < 900) {
      // dozens
      limits.min = currentLimits.Min_Dozen_ColumnBet;
      limits.max = currentLimits.Max_Dozen_ColumnBet;
    } else if (index >= 900 && index < 1000) {
      // outside bets
      limits.min = currentLimits.Min_OutsideBet;
      limits.max = currentLimits.Max_OutsideBet;
    }

    return limits;
  };
})();

export const doubleBet = (state) => map((b) => {
  const { min, } = getIndexLimits(b.index, state);
  const value = b.value * 2;
  const valid = value >= min;
  return { ...b, value, valid, };
});

export const doubleBets = (state) => compose(
  evolve({
    current: doubleBet(state),
    simple: doubleBet(state),
    neighbours: doubleBet(state),
    french: doubleBet(state),
    totalBet: (t) => t * 2,
  }),
  betsSelector
)(state);

const { notification, } = notificationActions;
const { dialog, } = dialogActions;

const getSimpleNotificationPayload = ({
  status,
  value,
  betAutoAdjust,
  maxTableLimit,
  maxIndexLimit,
  minIndexLimit,
}) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_TABLE_LIMIT:
      return value
        ? { message: 'notifications.bet_over_table_max_limit_adjusted', }
        : { message: 'notifications.bet_over_table_max_limit', variables: { maxTableLimit, }, };
    case STATUS.OVER_INDEX_LIMIT:
      return { message: 'notifications.bet_over_index_max_limit_adjusted', variables: { maxIndexLimit, }, };
    case STATUS.BELOW_INDEX_LIMIT:
      return betAutoAdjust
        ? {
          autoAdjust: true,
          event_name: STATUS.BELOW_INDEX_LIMIT,
          message: 'notifications.bet_below_index_min_limit_adjusted',
        }
        : { message: 'notifications.bet_below_index_min_limit', variables: { minIndexLimit, }, };
    default:
      return null;
    }
  })();
  return payload;
};

const getGroupNotificationPayload = ({ status, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.OVER_INDEX_LIMIT:
    case STATUS.OVER_TABLE_LIMIT:
      return { message: 'notifications.group_bet_over_max_limits', };
    case STATUS.BELOW_INDEX_LIMIT:
    case STATUS.BELOW_TABLE_LIMIT:
      return { message: 'notifications.group_bet_below_min_limits', };
    default:
      return null;
    }
  })();

  return payload;
};

export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};

export const validateBalance = (bet, state) => {
  const jackpotEnabled = shouldShowJackpotSelector(state);
  const jackpotBetValue = jackpotBetValueSelector(state);
  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state) + (jackpotEnabled ? jackpotBetValue : 0);

  const ok = balance - totalBet >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
};

export const validateLimits = (bet, state) => {
  const balance = userBalanceSelector(state);
  // bet limits
  const currentBets = currentBetsSelector(state);
  const { min: minIndexLimit, max: maxIndexLimit, } = getIndexLimits(bet.index, state);
  const existingBet = (currentBets[bet.index] || {}).value || 0;

  const { betAutoAdjust, } = generalConfigSelector(state);

  let status = bet.value + existingBet < minIndexLimit
    ? STATUS.BELOW_INDEX_LIMIT
    : bet.value + existingBet > maxIndexLimit
      ? STATUS.OVER_INDEX_LIMIT
      : STATUS.VALID;

  let ok = status === STATUS.VALID;
  const diff = maxIndexLimit - existingBet;

  let value = ok
    ? bet.value
    : clamp(betAutoAdjust ? minIndexLimit - existingBet : bet.value > diff ? diff : bet.value, diff, bet.value);

  // table limits
  const { Max_Bet: maxTableLimit, } = currentLimitsSelector(state);

  const totalBet = totalBetSelector(state);

  // JackpotBet is not counted towards maxTableLimit
  if (totalBet + value > maxTableLimit) {
    status = STATUS.OVER_TABLE_LIMIT;
    ok = false;
    value = maxTableLimit - totalBet;
  }

  const jackpotEnabled = shouldShowJackpotSelector(state);
  const jackpotBetValue = jackpotBetValueSelector(state);

  // Account for JackpotBet only on low balance
  if (value > balance - (totalBet + jackpotEnabled ? jackpotBetValue : 0)) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);

  const actions = [
    ...(status === STATUS.INVALID_BALANCE
      ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
      : [
        notification.add(
          getSimpleNotificationPayload({
            status,
            value,
            betAutoAdjust,
            minIndexLimit,
            maxIndexLimit,
            maxTableLimit,
          })
        ),
      ]),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: !(status === STATUS.BELOW_INDEX_LIMIT && !betAutoAdjust && value + existingBet < minIndexLimit),
      }),
    },
    actions,
  };
};

const validateCoverage = (bet, state) => {
  const { betCoverage = 100, } = rouletteConfigSelector(state);

  let ok = +betCoverage === 100;

  if (!ok) {
    const currentBets = currentBetsSelector(state);
    const allowedNumbersCount = Math.floor((36 * betCoverage) / 100);

    const currentCoveredNumbers = getCoveredNumbers(currentBets);

    const bets = has('index', bet) ? { [bet.index]: bet, } : bet;
    const nextCoveredNumbers = getCoveredNumbers(bets, currentCoveredNumbers);

    ok = nextCoveredNumbers.length <= allowedNumbersCount;
  }

  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.NOT_ALLOWED,
    bet,
    actions: !ok ? [ notification.add({ message: 'notifications.bet_not_allowed', }), ] : [],
  };
};

const pipeValidations = (validations) => (bet, state) => reduce(
  (res, fn) => (!res.valid ? res : fn(res.bet, state)),
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

const validateSimpleBet = pipeValidations([ validateRoundStatus, validateCoverage, validateBalance, validateLimits, ]);
const isOk = prop('ok');
const isValid = prop('valid');

export function validateSimpleBets(bets, state) {
  const results = values(bets).map((bet) => validateSimpleBet(bet, state));

  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, STATUS.VALID),
    map(prop('status'))
  )(results);

  const ok = all(isOk, results);
  const valid = all(isValid, results) && !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);

  return {
    ok,
    valid,
    status,
    bets: valid
      ? compose(
        reduce(merge, {}),
        map(
          compose(
            unless(isEmpty, (bet) => ({ [bet.index]: bet, })),
            prop('bet')
          )
        )
      )(results)
      : null,
    initialBets: bets,
    actions: compose(
      flatten,
      map(
        compose(
          reject(
            compose(
              isNil,
              prop('payload')
            )
          ),
          prop('actions')
        )
      )
    )(results),
  };
}

export function validateGroupBet(bets, state) {
  // validate simple bets
  const result = validateSimpleBets(bets, state);

  let { ok, status, valid, actions, } = result;
  valid = ok;

  if (valid) {
    // validate balance for total bet
    const currentTotalBet = getTotalBet(result.bets || {});
    const balance = userBalanceSelector(state);
    const totalBet = totalBetSelector(state);
    const hasBalance = totalBet + currentTotalBet <= balance;

    status = hasBalance ? result.status : STATUS.INVALID_BALANCE;
    ok = hasBalance ? result.ok : false;
    valid = ok;
    !valid && (actions = [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]);
  }

  if (valid) {
    // validate table limits
    const { Max_Bet: maxTableLimit, } = currentLimitsSelector(state);
    const currentTotalBet = getTotalBet(result.bets || {});
    const totalBet = totalBetSelector(state);
    const isOverLimit = totalBet + currentTotalBet > maxTableLimit;

    status = isOverLimit ? STATUS.OVER_TABLE_LIMIT : result.status;
    ok = isOverLimit ? false : result.ok;
    !ok && (actions = [ notification.add(getGroupNotificationPayload({ status, })), ]);
  }

  // coverage validation
  const coverageResult = valid ? validateCoverage(result.bets, state) : {};

  return {
    ...result,
    ok,
    status,
    valid,
    actions,
    ...coverageResult,
  };
}

export function validateNeighborBets(bets, state) {
  const american = isAmericanRouletteSelector(state);
  const straightBets = convertNeighborsToStraights(american)(bets);
  const result = validateGroupBet(straightBets, state);

  if (result.status === STATUS.BELOW_INDEX_LIMIT) {
    result.valid = true;
  }

  return {
    ...result,
    initialBets: bets,
    actions:
      status === STATUS.INVALID_BALANCE
        ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
        : [ notification.add(getGroupNotificationPayload({ status: result.status, })), ],
  };
}

export function validateFrenchBets(bets, state) {
  const simpleBets = convertFrenchToSimple(bets);
  const result = validateGroupBet(values(simpleBets), state);

  if (result.status === STATUS.BELOW_INDEX_LIMIT) {
    result.valid = true;
  }

  return {
    ...result,
    initialBets: map(
      (v) => ({
        ...v,
        valid: compose(
          all(isValid),
          values
        )(result.bets),
      }),
      bets
    ),
    actions:
      result.status === STATUS.INVALID_BALANCE
        ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
        : [ notification.add(getGroupNotificationPayload({ status: result.status, })), ],
  };
}

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();
  const s = last(h) || INITIAL_STATE;
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return ok
    ? { ok, actions: [ betActions.history.apply({ ...s, history: h, }), ], }
    : { ok, actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ], };
}

export function validateRebet(_, state) {
  const lb = lastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;

  return ok
    ? {
      ok,
      actions: [ betActions.bet.apply(lb), ],
    }
    : {
      ok,
      actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
    };
}

export function validateDouble(_, state) {
  const { current = {}, } = betsSelector(state);
  const tb = totalBetSelector(state);
  const b = userBalanceSelector(state);

  const jackpotEnabled = shouldShowJackpotSelector(state);
  const jackpotBetValue = jackpotBetValueSelector(state);

  // JackpotBet will not be doubled
  const status = tb * 2 + (jackpotEnabled ? jackpotBetValue : 0) > b ? STATUS.INVALID_BALANCE : STATUS.VALID;
  const ok = status === STATUS.VALID;
  const actions = ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions,
  };

  if (status === STATUS.VALID) {
    result = validateGroupBet(current, state);
    const isSimpleBet = Object.keys(result.bets).length === 1;
    result.actions = result.ok
      ? [ isSimpleBet ? betActions.bet.set(result.bets) : betActions.bet.apply(doubleBets(state)), ]
      : [ notification.add(getGroupNotificationPayload({ status: result.status, })), ];
    result.valid = result.ok;
  }

  return result;
}
