export const NUMBERS = [
  { percent: 0, number: 32, color: 'RED', },
  { percent: 0, number: 15, color: 'BLACK', },
  { percent: 0, number: 19, color: 'RED', },
  { percent: 0, number: 4, color: 'BLACK', },
  { percent: 0, number: 21, color: 'RED', },
  { percent: 0, number: 2, color: 'BLACK', },
  { percent: 0, number: 25, color: 'RED', },
  { percent: 0, number: 17, color: 'BLACK', },
  { percent: 0, number: 34, color: 'RED', },
  { percent: 0, number: 6, color: 'BLACK', },
  { percent: 0, number: 27, color: 'RED', },
  { percent: 0, number: 13, color: 'BLACK', },
  { percent: 0, number: 36, color: 'RED', },
  { percent: 0, number: 11, color: 'BLACK', },
  { percent: 0, number: 30, color: 'RED', },
  { percent: 0, number: 8, color: 'BLACK', },
  { percent: 0, number: 23, color: 'RED', },
  { percent: 0, number: 10, color: 'BLACK', },
  { percent: 0, number: 5, color: 'RED', },
  { percent: 0, number: 24, color: 'BLACK', },
  { percent: 0, number: 16, color: 'RED', },
  { percent: 0, number: 33, color: 'BLACK', },
  { percent: 0, number: 1, color: 'RED', },
  { percent: 0, number: 20, color: 'BLACK', },
  { percent: 0, number: 14, color: 'RED', },
  { percent: 0, number: 31, color: 'BLACK', },
  { percent: 0, number: 9, color: 'RED', },
  { percent: 0, number: 22, color: 'BLACK', },
  { percent: 0, number: 18, color: 'RED', },
  { percent: 0, number: 29, color: 'BLACK', },
  { percent: 0, number: 7, color: 'RED', },
  { percent: 0, number: 28, color: 'BLACK', },
  { percent: 0, number: 12, color: 'RED', },
  { percent: 0, number: 35, color: 'BLACK', },
  { percent: 0, number: 3, color: 'RED', },
  { percent: 0, number: 26, color: 'BLACK', },
  { percent: 0, number: 0, color: 'GREEN', },
];

export const AMERICAN_NUMBERS = [
  { percent: 0, number: 27, color: 'RED', },
  { percent: 0, number: 10, color: 'BLACK', },
  { percent: 0, number: 25, color: 'RED', },
  { percent: 0, number: 29, color: 'BLACK', },
  { percent: 0, number: 12, color: 'RED', },
  { percent: 0, number: 8, color: 'BLACK', },
  { percent: 0, number: 19, color: 'RED', },
  { percent: 0, number: 31, color: 'BLACK', },
  { percent: 0, number: 18, color: 'RED', },
  { percent: 0, number: 6, color: 'BLACK', },
  { percent: 0, number: 21, color: 'RED', },
  { percent: 0, number: 33, color: 'BLACK', },
  { percent: 0, number: 16, color: 'RED', },
  { percent: 0, number: 4, color: 'BLACK', },
  { percent: 0, number: 23, color: 'RED', },
  { percent: 0, number: 35, color: 'BLACK', },
  { percent: 0, number: 14, color: 'RED', },
  { percent: 0, number: 2, color: 'BLACK', },
  { percent: 0, number: 0, color: 'GREEN', },
  { percent: 0, number: 28, color: 'BLACK', },
  { percent: 0, number: 9, color: 'RED', },
  { percent: 0, number: 26, color: 'BLACK', },
  { percent: 0, number: 30, color: 'RED', },
  { percent: 0, number: 11, color: 'BLACK', },
  { percent: 0, number: 7, color: 'RED', },
  { percent: 0, number: 20, color: 'BLACK', },
  { percent: 0, number: 32, color: 'RED', },
  { percent: 0, number: 17, color: 'BLACK', },
  { percent: 0, number: 5, color: 'RED', },
  { percent: 0, number: 22, color: 'BLACK', },
  { percent: 0, number: 34, color: 'RED', },
  { percent: 0, number: 15, color: 'BLACK', },
  { percent: 0, number: 3, color: 'RED', },
  { percent: 0, number: 24, color: 'BLACK', },
  { percent: 0, number: 36, color: 'RED', },
  { percent: 0, number: 13, color: 'BLACK', },
  { percent: 0, number: 1, color: 'RED', },
  { percent: 0, number: 37, color: 'GREEN', },
];
